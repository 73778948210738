import React,{useEffect} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/autoplay';
import 'swiper/css';

import AOS from 'aos';
import 'aos/dist/aos.css';


const Hero = () => {

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <Swiper>
            <SwiperSlide>
                <section id="hero" className="d-flex align-items-center" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <div className="overlay"></div>
                    <video src="https://player.vimeo.com/external/439267198.hd.mp4?s=8adb372e8e3f9a6a206150c28235b9d077d40c0d&profile_id=174" autoPlay loop muted style={{'width':'100%'}}/>
                    <div className="content" data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="1500">
                        <h1 className="text-white text-center">Guwahati Public School Alumni Association</h1>
                        <p className="text-center">Far far away, behind the word mountains, far from the countries Vokalia.</p>
                    </div>
                </section>
            </SwiperSlide>
        </Swiper>
    )
}

export default Hero;