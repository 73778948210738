import React,{useEffect} from "react";

const GivingPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <h1>working</h1>
    )
}

export default GivingPage;