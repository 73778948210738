import React, { useState, useEffect } from 'react';

import PhotoAlbum from "react-photo-album";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const GalleryPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const photos = [
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20assembly.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20annual%20day.jpg", width: 1600, height: 800 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20art%20and%20craft.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20class%20room.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20dance.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20school%20building.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20school%20exhibition.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20traditional%20dance.jpg", width: 1600, height: 1200 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20assembly.jpg", width: 800, height: 600 },
        { src: "https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20annual%20day.jpg", width: 1600, height: 800 },
    ];


    const [open, setOpen] = React.useState(false);
    const [img, setImg]= useState();
    const handleOpen = (event) =>{
         setOpen(true);
         console.log(event.photo);
         setImg(event.photo.src)

    }
    const handleClose = () => setOpen(false);

    useEffect(() => {
        AOS.init();
      }, [])

    return ( 
       <section className='tabs box' id='tabs'>
            <div className='container' style={{'position':'inherit'}} data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">

                <div className="text-center">
                    <h1 className='text-white'>Gallery</h1>
                </div>

               <div className='tab-content mt-4' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <div className='tab-pane active show' id='tab-1'>
                        <div>
                            <PhotoAlbum layout="masonry" photos={photos} onClick={(photo)=>handleOpen(photo)}/>

                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={img} className="img-fluid"/>
                                </Box>
                            </Modal>
                        </div>
                    </div>

                    <div className='tab-pane' id='tab-2'>
                        <div>
                            <PhotoAlbum  layout="masonry" photos={photos} onClick={(photo)=>handleOpen(photo)}/>

                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={img} className="img-fluid"/>
                                </Box>
                            </Modal>
                        </div>
                    </div>

                    <div className='tab-pane' id='tab-3'>
                        <div>
                            <PhotoAlbum layout="masonry" photos={photos} onClick={(photo)=>handleOpen(photo)}/>

                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={img} className="img-fluid"/>
                                </Box>
                            </Modal>
                        </div>
                    </div>
               </div>
            </div>
       </section>
    )
}

export default GalleryPage;