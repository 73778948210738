import React,{useEffect} from 'react';

import { useNavigate } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

const ConnectPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <section className='p-5'>
            <div className='container' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <div className='row justify-content-center'>
                    <div className='col-md-3'>
                        <div className='alumniContent'>
                            <div className='text-center p-4'>
                                <span><i>Photos</i></span>
                                <h4 className='text-white mt-2 mb-4'>VISIT GALLERY</h4>
                                <span className='btnContent' onClick={()=>navigate('/gallery')}>MORE</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='alumniContent content1'>
                            <div className='text-center p-4'>
                                <span><i>Alumni</i></span>
                                <h4 className='text-white mt-2 mb-4'>STAY CONNECTED</h4>
                                <span  className='btnContent' onClick={()=>navigate('/connect')}>MORE</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='alumniContent content2'>
                            <div className='text-center p-4'>
                                <span><i>Media</i></span>
                                <h4 className='text-white mt-2 mb-4'>LATEST BLOGS</h4>
                                <span  className='btnContent' onClick={()=>navigate('/media')}>MORE</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='alumniContent content3'>
                            <div className='text-center p-4'>
                                <span><i>Batch</i></span>
                                <h4 className='text-white mt-2 mb-4'>VISIT BATCHES</h4>
                                <span  className='btnContent' onClick={()=>navigate('/batch')}>MORE</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConnectPage;