import React, { useState, useEffect } from "react";

import axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from '@mui/material/Card';
import Swal from 'sweetalert2';


const Connect = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        batch: '',
        permanent_address: '',
        current_address: '',
        birth_date: '',
        occupation: '',
        certificate: '',
        admission_year: '',
        passing_year: ''
    });


    const [certificate, setCertificate] = useState();
    const [photo, setPhoto] = useState();

    const handleCertificate=(e)=>{
        setCertificate(e.target.files[0])
    }
    const handlePhoto=(e)=>{
        setPhoto(e.target.files[0])
    }

    const handleInput = (e) => {
        // console.log(e.target.name);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {

        const postData = new FormData();
        postData.append('name', formData.name);
        postData.append('email', formData.email);
        postData.append('contact', formData.contact);
        postData.append('batch', formData.batch);
        postData.append('permanent_address', formData.permanent_address);
        postData.append('current_address', formData.current_address);
        postData.append('birth_date', formData.birth_date);
        postData.append('occupation', formData.occupation);
        postData.append('admission_year', formData.admission_year);
        postData.append('passing_year', formData.passing_year);

        postData.append('certificate', certificate);
        postData.append('photo', photo);

        
        
        axios.post(`${process.env.REACT_APP_MAIN_API}/postAlumni`, postData)
            .then((res) => {
                console.log(res);

                Swal.fire(
                    'Good job!',
                    'Registered Successfully',
                    'success'
                )
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        AOS.init();
    }, [])


    return (
        <>

            <Card variant="outlined mb-3">
                <div className="container  mt-4">
                    <div className="row border text-center p-3">
                        <h2> Join As Alumni </h2>
                    </div>
                    <div className="row ">
                        <div className="col-md-8 border">
                            <div className="row mt-4">

                                <div className="col-md-6 form-group">
                                    <label>Your Name</label>
                                    <input type="text" name="name" value={formData.name} onChange={handleInput} placeholder="Enter your name" className="form-control" />
                                    <br />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Email Address</label>
                                    <input type="email" name="email" placeholder="Email Id" className="form-control" value={formData.email} onChange={handleInput} />
                                    <br />
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Contact</label>
                                    <input type="number" name="contact" placeholder="number" className="form-control" value={formData.contact} onChange={handleInput} />
                                    <br />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Batch</label>
                                    <select className="form-select" aria-label="Default select example" name="batch" value={formData.batch} onChange={handleInput}>
                                        <option value="">Select Your Batch</option>
                                        <option value="2000">2000</option>
                                        <option value="2001">2001</option>
                                        <option value="2002">2002</option>
                                        <option value="2003">2003</option>
                                        <option value="2004">2004</option>
                                        <option value="2005">2005</option>
                                        <option value="2006">2006</option>
                                        <option value="2007">2007</option>
                                        <option value="2008">2008</option>
                                        <option value="2009">2009</option>
                                        <option value="2010">2010</option>
                                        <option value="2011">2011</option>
                                        <option value="2012">2012</option>
                                        <option value="2013">2013</option>
                                        <option value="2014">2014</option>
                                        <option value="2015">2015</option>
                                        <option value="2016">2016</option>
                                        <option value="2017">2017</option>
                                        <option value="2018">2018</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>

                                    </select>
                                    <br />
                                </div>

                                <div className="col-12 form-group">
                                    <label>Permanent Address</label>
                                    <input type="text" name="permanent_address" value={formData.permanent_address} onChange={handleInput} placeholder="Permanent Address" className="form-control" />
                                    <br />
                                </div>


                                <div className="col-12 form-group">
                                    <label>Current Address</label>
                                    <input type="text" name="current_address" placeholder="Current Address" className="form-control" value={formData.current_address} onChange={handleInput} />
                                    <br />
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Date Of Birth</label>
                                    <input type="date" name="birth_date" className="form-control" placeholder="date of birth" required value={formData.birth_date} onChange={handleInput} />
                                    <br />
                                </div>


                                <div className="col-md-6 form-group">
                                    <label>Current Occupation</label>
                                    <input type="text" name="occupation" className="form-control" placeholder="Current Occupation" required value={formData.occupation} onChange={handleInput} />
                                    <br />
                                </div>


                                <div className="col-md-6 form-group">
                                    <label>Admission Year</label>
                                    <input type="date" name="admission_year" className="form-control" id="adYear" placeholder="admission year" required value={formData.admission_year} onChange={handleInput} />
                                    <br />
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Passing Year</label>
                                    <input type="date" className="form-control" name="passing_year" id="psYear" placeholder="passing year" required value={formData.passing_year} onChange={handleInput} />

                                    <br />
                                </div>


                                <div className="col-12 form-group">

                                    <label>Photo (Passport size)</label>
                                    <input type="file" name="photo" placeholder="certificate" className="form-control"  onChange={handlePhoto} />
                                    <br />

                                </div>
                                <div className="col-12 form-group">

                                    <label>Certificates (Any certificate for verification)</label>
                                    <input type="file" name="certificate" placeholder="certificate" className="form-control" onChange={handleCertificate} />
                                    <br />

                                </div>

                                <div className="col-12 form-group my-4">

                                    <button className="btnAlumni" onClick={handleSubmit}>Register</button>
                                    <br />

                                </div>
                            </div>

                        </div>

                        <div className="col-md-4 border">
                            <div className="mt-5 pt-5">
                                <div className="text-center py-3">
                                    <h4>Note </h4>
                                    <span>Upload any kind of certificate <br/>Eg :  Marksheets, AdmitCard, Degree etc. <br/>for verification .</span>
                                    <br/><br/>
                                    <span>
                                        Only passport size images are accepted <br/>
                                        Only jpg/jpeg and png files <br/> are allowed !
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default Connect;