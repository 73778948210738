import React, {useEffect} from "react";

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import AOS from 'aos';
import 'aos/dist/aos.css';


const CoreCommunityPage = () => {

    useEffect(() => {
        AOS.init();
      }, []);

      useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <section className='box'>
            <div className='container' style={{'position':'inherit'}} data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <div className="text-center">
                    <h1 className="mt-3 mb-5 text-white">Core Members</h1>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-md-3'>
                        <div className='alumniContent content0'>
                            <div className="thumbImage">
                                <img src='https://bootstrapmade.com/demo/templates/Presento/assets/img/team/team-1.jpg' alt='image' className='img-fluid contentImage'/>
                                <div className="team-socials">
                                    <a href="#"><FacebookOutlinedIcon /></a>
                                    <a href="#"><InstagramIcon /></a>
                                    <a href="#"><TwitterIcon /></a>
                                </div>
                            </div>
                            <div className='text-center p-4'>
                                <span><i>Principal</i></span>
                                <h4 className='text-white mt-2 mb-4'>Walter White</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='alumniContent content1'>
                            <div className="thumbImage">
                                <img src='https://bootstrapmade.com/demo/templates/Presento/assets/img/team/team-2.jpg' alt='image' className='img-fluid contentImage'/>
                                <div className="team-socials">
                                    <a href="/"><FacebookOutlinedIcon /></a>
                                    <a href="/"><InstagramIcon /></a>
                                    <a href="/"><TwitterIcon /></a>
                                </div>
                            </div>
                            <div className='text-center p-4'>
                                <span><i>CEO</i></span>
                                <h4 className='text-white mt-2 mb-4'>Sarah Jhonson</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='alumniContent content2'>
                            <div className="thumbImage">
                                <img src='https://bootstrapmade.com/demo/templates/Presento/assets/img/team/team-3.jpg' alt='image' className='img-fluid contentImage'/>
                                <div className="team-socials">
                                    <a href="/"><FacebookOutlinedIcon /></a>
                                    <a href="/"><InstagramIcon /></a>
                                    <a href="/"><TwitterIcon /></a>
                                </div>
                            </div>
                            <div className='text-center p-4'>
                                <span><i>Faculty</i></span>
                                <h4 className='text-white mt-2 mb-4'>William Anderson</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='alumniContent content3'>
                            <div className="thumbImage">
                                <img src='https://bootstrapmade.com/demo/templates/Presento/assets/img/team/team-4.jpg' alt='image' className='img-fluid contentImage'/>
                                <div className="team-socials">
                                    <a href="/"><FacebookOutlinedIcon /></a>
                                    <a href="/"><InstagramIcon /></a>
                                    <a href="/"><TwitterIcon /></a>
                                </div>
                            </div>
                            <div className='text-center p-4'>
                                <span><i>Founder</i></span>
                                <h4 className='text-white mt-2 mb-4'>Amanda Jepson</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CoreCommunityPage;