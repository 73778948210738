import React,{useEffect} from "react";

import Connect from "../components/connect/connect";

const ConnectPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <Connect />
        </>
    )
}

export default ConnectPage;