import React,{useEffect} from 'react'
import Hero from '../components/home/hero'
import Box from '../components/home/Box'
import Alumni from '../components/home/alumni'
import UpcomingEvents from './eventPage'
import ConnectPage from '../components/home/content'
import BlogSection from "../components/home/blogSection";
import Contact from '../components/home/contact'



const Homepage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
            <Hero />
            <ConnectPage />
            <Box />
            <Alumni />
            <UpcomingEvents />
            <Contact />
            <BlogSection />
        </>
    )
}

export default Homepage;