import React,{useEffect} from "react";

import About from "../components/about/about";

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
            <About />
        </>
    )
}

export default AboutPage;