import React from 'react';
import { Routes, Route } from "react-router-dom";

import Dashboard from '../AdminPages/dashboard/dashboard';
import DashboardPage from '../AdminPages/admin/pages/Dashboard';
import AddBatchPage from '../AdminPages/admin/pages/addBatch';
import ViewBatchPage from '../AdminPages/admin/pages/viewBatch';
import RegisterPage from '../AdminPages/admin/pages/batchRequestPage';

import AddEvents from '../AdminPages/admin/pages/addEvents';
import ViewEvents from '../AdminPages/admin/pages/viewEvents';


const AdminInterface = () => {
    return (
        <>
            {/* <Dashboard /> */}

            <Routes>
                <Route path="/admin" element={<Dashboard />} >
                    <Route path="/admin" element={<DashboardPage />} />


                    <Route path="addBatch" element={<AddBatchPage />} />
                    <Route path="viewBatch" element={<ViewBatchPage />} />
                    <Route path="batchRequest" element={<RegisterPage />} />
                    <Route path="addEvents" element={<AddEvents />} />
                    <Route path="viewEvents" element={<ViewEvents />} />

                </Route>
                    <Route path="*" element={<Dashboard />} />
            </Routes>

        </>
    )
}

export default AdminInterface;