import React,{useEffect} from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <section className='box'>
            <div className='container' style={{'position':'inherit'}} data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <div className='row justify-content-center'>
                    <div className='col-lg-6'>
                        <div className='text-center'>
                            <h1 className="my-3 text-white">About Us</h1>
                            <p className='text-white' style={{'fontWeight':'500'}}>Guwahati Public School also known as Guwahati Public School. The school was established in 1982. Guwahati Public School is a Co-Ed school affiliated to Central Board of Secondary Education (CBSE) . It is managed by Assam Adarsha Vidyalaya Sanstha.<br/><br/>
                            Guwahati Public School (GPS) located at Panjabari Guwahati Dt- Kamrup Assam Kamrup Assam is one of the popular schools in India. The School has been rated by 20 people on iCBSE. The School has been recognized by Central Board of Secondary Education since a long time. The Guwahati Public School has been viewed 1293 times by the visitors on iCBSE.<br/><br/>
                            Guwahati Public School is an co-education institution. It is the 1st English medium school in Guwahati which is affialated to CBSE.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <img src='https://th.bing.com/th/id/OIP.rRbe8JrI6z9xYI5snYg8zAHaHa?pid=ImgDet&rs=1' alt="about" className='img-fluid'/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;