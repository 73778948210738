import React,{useEffect} from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

const BlogSection = () => {

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <section className="box">
            <div className="container" style={{'position':'inherit'}}>
                <div className="text-center">
                    <h1 className="text-white" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">Recent Blogs</h1>
                </div>
                <div className="row mt-5" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <div className="col-lg-4">
                        <div className="blog">
                            <div className="image">
                                <img src="https://preview.colorlib.com/theme/fox/images/image_2.jpg" className="img-fluid" />
                            </div>
                            <div className="p-5">
                                <h5>Skills To Develop Your Child Memory</h5>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <a href="/" className="btn"><span>Read More...</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="blog">
                            <div className="image">
                                <img src="https://preview.colorlib.com/theme/fox/images/image_2.jpg" className="img-fluid" />
                            </div>
                            <div className="p-5">
                                <h5>Skills To Develop Your Child Memory</h5>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <a href="/" className="btn"><span>Read More...</span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="blog">
                            <div className="image">
                                <img src="https://preview.colorlib.com/theme/fox/images/image_2.jpg" className="img-fluid" />
                            </div>
                            <div className="p-5">
                                <h5>Skills To Develop Your Child Memory</h5>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                                <a href="/" className="btn"><span>Read More...</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default BlogSection;