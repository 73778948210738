import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import DirectionsIcon from '@mui/icons-material/Directions';

const SelectInput = (props) => {
    const [value, setValue] = React.useState('');


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const inputSelect = () => {
        return (
            <>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                    <InputLabel >Batch Name</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={value}
                        onChange={handleChange}

                        label="Batch Name"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {props.values.map((batch) => (
                            <MenuItem
                                key={batch}
                                value={batch}
                            >
                                {batch}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        )
    }

    return (
        <div>

            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'auto' }}
            >
                {inputSelect()}

                {/* <TextField id="standard-basic" label="Alumni Name" variant="standard" /> */}


                <IconButton type="button" onClick={()=>props.filterBatch(value)} sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>



        </div>
    );
}

export default SelectInput