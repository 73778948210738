import React,{useEffect} from 'react'
import Batch from '../components/batch/batch';


const BatchPage=()=>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>
        {/* <Navbar/> */}
        <Batch />
        </>
    )
}

export default BatchPage;