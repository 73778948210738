import React from 'react'
import { Routes, Route } from "react-router-dom";
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';

import Homepage from '../PublicPages/homepage';
import BatchPage from '../PublicPages/batchpage';
import AboutPage from '../PublicPages/aboutpage';
import ConnectPage from '../PublicPages/connectpage';
import UpcomingEvents from '../PublicPages/eventPage';
import GalleryPage from '../PublicPages/galleryPage';
import CoreCommunityPage from '../PublicPages/coreCommunityPage';
import AgmPage from '../PublicPages/agmPage';
import FinancePage from '../PublicPages/financePage';
import MediaPage from '../PublicPages/mediaPage';
import DonorRollPage from '../PublicPages/donorpage';
import GivingPage from '../PublicPages/givingpage';
import RecognitionPage from '../PublicPages/recognitionpage';
import SharePage from '../PublicPages/sharepage';

import Box from '../components/home/Box';
import Login from '../AdminPages/form/login';

const PublicInterface = () => {
    return (
        <>
            <Nav />
            <Routes>
                <Route path="/" element={<Homepage />} />

                <Route path="/box" element={<Box />} />
                <Route path="/batch" element={<BatchPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/connect" element={<ConnectPage />} />
                <Route path="/events" element={<UpcomingEvents />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/community" element={<CoreCommunityPage />} />
                <Route path="/agm" element={<AgmPage />} />
                <Route path="/finance" element={<FinancePage />} />
                <Route path="/media" element={<MediaPage />} />
                <Route path="/donor" element={<DonorRollPage />} />
                <Route path="/giving" element={<GivingPage />} />
                <Route path="/recognition" element={<RecognitionPage />} />
                <Route path="/share" element={<SharePage />} />
                <Route path="/login" element={<Login />} />


            </Routes>

            <Footer />
        </>
    )
}

export default PublicInterface;