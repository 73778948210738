import React,{useEffect} from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <section className="p-5">
            <div className="container">
                <div className="text-center my-4" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <h1>Get In Touch</h1>
                </div>
                <div className="row justify-content-center" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <div className="col-lg-6">
                        <div className="box-form p-5">
                            <div className="right">
                                <div className="inputs">
                                    <label>UserName</label>
                                    <input type="text" name="name" placeholder="username" className="form-control"/>
                                    <br />
                                    <label>Email Address</label>
                                    <input type="email" name="email" placeholder="Email Id" className="form-control"/>
                                    <br />
                                    <label>Contact</label>
                                    <input type="number" name="contact" placeholder="number" className="form-control"/>
                                    <br/>
                                    <label>Message</label>
                                    <input type="text" name="message" placeholder="message" className="form-control"/>
                                </div>
                                <br/>
                                <br/>
                                <button className="btnAlumni">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mt-3">
                            <div className="mapouter"><div className="gmap_canvas"><iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=guwahati public school&t=&z=10&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                            </iframe></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;