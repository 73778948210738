import React,{useEffect} from "react";

const SharePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <h1>Working</h1>
    )
}

export default SharePage;