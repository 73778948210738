import React, {useEffect, useState} from 'react'

import './App.css';


import PublicInterface from './interface/publicInterface';
import AdminInterface from './interface/adminInterface';

function App() {

  const [user, setUser] =useState('');

  useEffect(()=>{
    if(sessionStorage.getItem('user')){
      setUser('admin')
    }else{
      setUser('public')  
    }

  }, [user]);



  return (
    <>

      {user === 'public' &&
        <PublicInterface />
      }

      {user === 'admin' &&
        <AdminInterface />
      }
      
      
    </>
  );
}

export default App;
