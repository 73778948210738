import API from "./apiConfig";

export default{

    
    getBatch(){
        return API.get('/getBatch');
    },

    varifyAlumni(data, id){
        return API.post('/varifyAlumni', {data, id});
    },





    // login 
    register(formData){
        return API.post('/register', {formData});
    },

    login(email, password){
        return API.post('/login', {email, password})
    }
    



}