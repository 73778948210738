import React from "react";

const Reports = () => {

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Welcome to Admin Page</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reports;