import React, {useState} from 'react';

import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import api from '../../API/api';
import AuthUser from '../../API/token';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';


const Login = () => {

    const { setToken } = AuthUser();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const submit = () => {
        setLoading(true);

        api.login(email, password)
            .then((res) => {
                console.log("Login success==>", res);
                setToken(res.data.user, res.data.access_token)
                setLoading(false);
            })
            .catch((err) => {
                console.log("login error==>", err.response);
                setLoading(false);
                setError(true);
            })
    }

    const Loading = () => {
        return (
            <Stack sx={{ color: 'grey.500' }} style={{ 'alignItems': 'center' }}>
                <CircularProgress color="inherit" />
            </Stack>
        );
    }




    return (
        <>
            <section className='p-5'>
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <form className='p-5 border bg-light'>
                                <div className='text-center'>
                                    <p className="text-center">Sign In with :</p>
                                    <button type="button" className="btn btn-link btn-floating">
                                        <FacebookOutlinedIcon/>
                                    </button>

                                    <button type="button" className="btn btn-link btn-floating">
                                        <InstagramIcon/>
                                    </button>

                                    <button type="button" className="btn btn-link btn-floating">
                                        <TwitterIcon/>
                                    </button>

                                    <p className="text-center mt-4">or :</p>

                                </div>

                                <div className="form-outline mb-4">
                                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} id="form2Example1" className="form-control" placeholder='Email or Username'/>
                                </div>


                                <div className="form-outline mb-4">
                                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} id="form2Example2" className="form-control" placeholder='Password'/>
                                </div>


                                <div className="row mb-4">
                                    <div className="col d-flex justify-content-center">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="form2Example31" checked />
                                            <label className="form-check-label text-dark" for="form2Example31"> Remember me </label>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <a href="#!">Forgot password?</a>
                                    </div>
                                </div>


                                

                                {error && <Alert severity="error">Something went wrong !</Alert>
                                        }


                                        {loading ? <Loading /> :
                                            <button type="button" onClick={submit} className="btn btn-primary btn-block mb-4" style={{'width':'100%'}}>Sign in</button>
                                        }

                                <div className="text-center">
                                    <p className="text-center" >Not a member? <a href="#!">Register</a></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Login;