import React,{useEffect} from "react";

const FinancePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return (
        <>Working</>
    )
}

export default FinancePage;