import React,{useEffect} from "react";
import { NavLink } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <>
            <footer className="footer text-center text-lg-start p-5 text-white bg-dark ">
                <div className="container" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <div className="row">
                        <div className="col-md-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                        <h4>Location</h4>
                        <p>Panjabari, Botahguli, Guwahati</p>
                        <h4>Email</h4>
                        <a href="/" className="text-white text-decoration-none"><span>guwahatipublicschool@gmail.com</span></a>
                        </div>
                        <div className="col-md-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                            <h4>Quick Links</h4>
                            <ul>
                                <li>Home</li>
                                <li>About</li>
                                <li>Contact</li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                        <div className="col-md-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                            <h4>Features</h4>
                            <ul>
                                <li>Jobs</li>
                                <li>Brand Assets</li>
                                <li>Investor Relation</li>
                                <li>Terms Of Service</li>
                            </ul>
                        </div>
                        <div className="col-md-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                            <h4>Resources</h4>
                            <ul>
                                <li>Guides</li>
                                <li>Research</li>
                                <li>Experts</li>
                                <li>Agencies</li>
                            </ul>
                        </div>
                        <div className="col-lg-8 col-sm-12 footer-text m-0 text-white mt-4">
                            <p>Copyright © 2018 All rights reserved</p>
                        </div>
                        <div className="col-md-4">  
                            <div className="form mt-4">
                                <form target="_blank" method="get" className="form-inline" noValidate={true}>
                                    <input className="form-control" name="Email" placeholder="Your Email Address" type="email" required />
                                    <span className="btnSearch">Subscribe</span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;