import React, { useState, useRef, useMemo } from "react";
import JoditEditor from 'jodit-react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddEvents = () => {

    const editor = useRef(null);

    const [files, setFile] = useState([]);

    const [formData, setFormData] = useState({
        title: '',
        date:'',
        time:'',
        cover: ''
    });

    const [details, setDetails] = useState('');

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleFile = (file) => {
        setFormData({
            ...formData,
            cover: file[0]
        })
    }

    const handleJodit = (e) => {
        setDetails(e);
    }

    const handlesubmit = (e) => {

        const api = process.env.REACT_APP_MAIN_API;

        e.preventDefault();

        const url = api + "/addEvents";

        const data = new FormData();

        data.append('cover', files[0])

        data.append('title', formData.title);
        data.append('details', details);
        data.append('date', formData.date);
        data.append('time', formData.time);



        axios.post(url, data).then(res => {
            console.log("==>",res.status);
            if (res.status == 200) {
                toast.success('Successfully Added', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setFormData({
                    title: '',
                    date: '',
                    time: '',
                    cover: ''
                });
                setDetails('');
                setFile([]);


            }
        })
            .catch(err => {
                console.log("err",err.response);
                toast.error('Something went wrong.. !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            })


    }




    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFile(
                acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
            )
        }
    })

    const images = files.map(file => (
        <img src={file.preview} alt="" key={file.name} style={{ 'width': '30%' }} className='mx-2 my-2' />
    ))

    const hasImages = images.length > 0;

    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 420,
        triggerChangeEvent: true,
        readonly: false,
    }


    const Jodit = () => {
        return useMemo(() => (
            <JoditEditor
                ref={editor}
                config={config}
                value={details}
                onChange={handleJodit}
            />
        ), [])
    }



    return (
        <>
            <ToastContainer />
            <section id="blogPanel" className="blogPanel my-0 py-0">
                <div className="container">
                    <div className="row">
                        <div className="my-3">
                            <label>Event Name</label>
                            <input type="text" className="form-control" onChange={handleInput} value={formData.title} name="title" id="title" placeholder="Enter event name here..." required />
                        </div>

                        <div className="my-3">
                            <div className="row">
                                <div className="col form-group">
                                    <label>Date</label><br />
                                    <input type="date" className="form-control" onChange={handleInput} value={formData.date} name="date" id="date" required />
                                </div>
                                <div className="col form-group">
                                    <label>Time</label><br />
                                    <input type="time" className="form-control" onChange={handleInput} value={formData.time} name="time" id="time" required />
                                </div>
                            </div>
                        </div>


                        <div className="my-3">
                            <label>Event Description</label>
                            {Jodit()}
                        </div>
                        
                        <div className="my-3" {...getRootProps()}>
                            <label>Cover Image</label>&nbsp;
                            <span className="text-danger">Dimension: 300px X 300px</span><br />

                            {!hasImages && (
                                <div className="dropArea py-4 text-center border rounded">
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAASdJREFUSEvtld0xBFEQRs9GQAgrAkRABogAEZDBEoIIEAEiIIMlAzIgAluHu2r26vtTNbVv229zp6dP99ffzExYc0zWXJ8NoKlwj0TbwAVwCOwBXr8Cj8AN8FmjtAAWfE5FozoWP0+wkFMD2PFDpfiwoJC7iFADKMNuU+TfBCfZieSKAHY+S5rn9e/TwWkAvgau8vMccAbcFrp+S0v2djSdZ/s1gJ270Ci+gOlAAp30Dmxlye5BZwn7ieEE2u6oALCzv4dSjg6bB/nu4wR4yQHeyDsyp+gQoCTpE3CcA747HLOcuDt3KJEjHTQgvYCPtLOVHTiSL1YtegGhRBbWx74DY0LHaQBdFv4PtKugllx5E8qi0y6XxUuAMd3/e7b1NR0N2wCaEi4AahcxGSsqP7MAAAAASUVORK5CYII=" />
                                    <input onChange={e => handleFile(e.target.files)} {...getInputProps()} />
                                    <p className="text text-center my-2">Drag and drop files here</p>
                                </div>
                            )}

                        </div>

                        {hasImages && (
                            <div className="text-center">
                                <div className="preview  my-3" style={{ 'display': 'flex', 'flexWrap': 'wrap', 'width': '100%' }}>
                                    {images}
                                </div>

                                <span className="btn btn-primary" {...getRootProps()} style={{ 'width': '200px' }}>
                                    <input {...getInputProps()} />
                                    Replace Image
                                </span>
                            </div>
                        )}

                        <div className="text-center my-3"><button className="btn btn-primary" onClick={handlesubmit} type="submit">Create Post</button></div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default AddEvents;