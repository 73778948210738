import React, { useEffect, useState } from 'react'
import SelectInput from '../atoms/inputs/select';
import Card from '@mui/material/Card';
import api from '../../API/api';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import LinearProgress from '@mui/material/LinearProgress';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const Batch = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data) => {
        setOpen(true);
        setData(data)

    }
    const handleClose = () => setOpen(false);

    // const values = [
    //     '2001',
    //     '2002',
    //     '2003',
    //     '2004',
    // ];
    const [values, setValues] = useState();

    const [allBatch, setAllbatch] = useState([]);
    const [batch, setBatch] = useState([]);

    useEffect(() => {
        getBatch();
    }, []);



    const filterBatch = (data) => {

        if (data === "") {
            getBatch();
            return;
        }


        const filterData = allBatch.filter((el) => {
            return el.batch === data
        })

        arrangeBatch(filterData);


    }


    const getBatch = () => {
        api.getBatch()
            .then((res) => {
                console.log(res.data.allbatch);
                console.log("batch=>", res.data.batch);
                setAllbatch(res.data.allbatch);

                arrangeBatch(res.data.allbatch);

                const aa = Array.from(new Set(res.data.allbatch.map((item) => item.batch)))
                console.log("okk=>", aa.sort());
                setValues(aa.sort())



            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    const arrangeBatch = (data) => {
        var result = data.reduce(function (r, a) {
            r[a.batch] = r[a.batch] || [];
            r[a.batch].push(a);
            return r;
        }, Object.create(null));

        console.log("==", result);
        setBatch(result)

        setLoading(false);

    }


    const modalView = () => {

        return (
            <>
                {data &&
                    <Modal
                        open={open}
                        onClose={handleClose}
                    >
                        <Box sx={style}>
                            <div className='row'>
                                <div className='col-sm-5'>
                                    <div className="image">
                                        {data.photo ? <img src={`${process.env.REACT_APP_BACKEND}assets/photo/${data.photo}`} className="img-fluid alumniImage" /> :
                                            <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" />
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-7'>
                                    <div className="p-2">
                                        <h6><strong>Name :</strong> {data.name}</h6>
                                        <p><strong>Email :</strong> {data.email}</p>
                                        <p><strong>Contact Number :</strong> {data.contact}</p>
                                        <p><strong>Current Address :</strong> {data.current_address}</p>
                                        <p><strong>Date Of Birth :</strong> {data.birth_date}</p>
                                        <p><strong>Current Occupation : </strong>{data.occupation}</p>
                                        <p><strong>Admission Year : </strong>{data.admission_year}</p>
                                        <p><strong>Passing Year : </strong>{data.passing_year}</p>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                }
            </>
        )
    }

    const EventSkenton = () => {
        return (
            <>
                <div className='container p-5'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="p-4">
                                <div>
                                    <Skeleton height={35} count={10} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }



    return (
        <>
            {loading ? <><LinearProgress color='secondary' /> <EventSkenton /></> :
                <div className="container">
                    <div className="m-3">

                        <div className="row">
                            <div className='col-12 d-flex justify-content-center'>
                                <SelectInput values={values} filterBatch={filterBatch} />
                            </div>
                        </div>


                        {Object.keys(batch).map((batch) => (
                            <div key={batch}>


                                <div className="row mt-4 justify-content-center border batch">
                                    <Card className='card'>
                                        <div className="col-12 text-center p-1">
                                            <h4 className='text-white'>Alumni Batch: {batch}</h4>
                                        </div>
                                    </Card>

                                    {allBatch.map((data) => (
                                        // <span key={data.id}>
                                        <>
                                            {data.batch === batch &&
                                                <div className="col-6 col-md-2">
                                                    <div className="mt-5">
                                                        <div className="blog border" onClick={() => handleOpen(data)}>
                                                            <div className="image">
                                                                {data.photo ? <img src={`${process.env.REACT_APP_BACKEND}assets/photo/${data.photo}`} className="img-fluid-batch alumniImage" /> :
                                                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid-batch alumniImage" />
                                                                }
                                                            </div>
                                                            <div className="p-2 text-center">
                                                                <h6>{data.name}</h6>
                                                                <p className="text-center">{data.passing_year}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        // </span>
                                    ))}
                                    {modalView()}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            }
        </>
    )
}

export default Batch;