import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import api from "../../API/api";

import AOS from 'aos';
import 'aos/dist/aos.css';


const Alumni = () => {

    const [allBatch, setAllbatch] = useState();



    useEffect(() => {
        AOS.init();

        getBatch();
    }, [])



    const getBatch = () => {
        api.getBatch()
            .then((res) => {
                console.log(res.data.allbatch);
                setAllbatch(res.data.allbatch)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <section className="p-5 alumni">
            <div className="text-center" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <h1>Recently Joined Alumni's</h1>
            </div>
            <div className="container" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <Swiper
                    loop={true}
                    modules={[Autoplay, Scrollbar, Pagination]}
                    //autoplay={false}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    speed={500}
                    pagination={{ clickable: true }}
                    spaceBetween={15}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        460: {
                            //   width: 640,
                            slidesPerView: 2,
                        },
                        // when window width is >= 768px
                        768: {
                            //   width: 768,
                            slidesPerView: 5,
                        },
                        968: {
                            //   width: 768,
                            slidesPerView: 6,
                        },
                    }}
                >
                    {allBatch && allBatch.map((el) => {

                        return (

                            <SwiperSlide>
                                <div className="mt-5">
                                    <div className="blog">
                                        <div className="image">
                                            {el.photo ? <img src={`${process.env.REACT_APP_BACKEND}assets/photo/${el.photo}`} className="img-fluid-batch alumniImage" /> :
                                                <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid-batch alumniImage" />
                                            }
                                        </div>
                                        <div className="p-2 text-center">
                                            <h6>{el.name}</h6>
                                            <p className="text-center">{el.batch}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )

                    })}

                    {/* <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>K SHARMA</h6>
                                    <p className="text-center">2019-2023</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>RAHUL SINGH</h6>
                                    <p className="text-center">2016-2020</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>PARTHA BORAH</h6>
                                    <p className="text-center">2020-2024</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>PARTHA BORAH</h6>
                                    <p className="text-center">2020-2024</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>PALLAVI SINGH</h6>
                                    <p className="text-center">2015-2019</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>K SHARMA</h6>
                                    <p className="text-center">2019-2023</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>RAHUL SINGH</h6>
                                    <p className="text-center">2016-2020</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>PARTHA BORAH</h6>
                                    <p className="text-center">2020-2024</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=" mt-5">
                            <div className="blog">
                                <div className="image">
                                    <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" alt="alumni" />
                                </div>
                                <div className="p-2 text-center">
                                    <h6>PARTHA BORAH</h6>
                                    <p className="text-center">2020-2024</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </section>
    )
}

export default Alumni;