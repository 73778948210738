import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import Swal from 'sweetalert2';
import api from '../../../API/api';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function DialogModal(props) {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmAlumni = (data) => {
        setOpen(false);

        if (data) {
            Swal.fire({
                title: "Are you sure this member is verified?",
                text: "It will visible on Public ",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('working');
                    varifyAlumni(data);
                    

                } else {
                    setOpen(true);
                    
                }
            })
        }
        if (!data) {
            Swal.fire({
                title: "Are you sure want to reject ?",
                text: "Once reject, you can edit it",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('working');
                    varifyAlumni(data);
                } else {
                    setOpen(true);
                }
            })
        }

    }

    const varifyAlumni = (data) => {

        api.varifyAlumni(data, props.data.id)
            .then((res) => {
                console.log(res);
                props.updateAlumni(true);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function createData(name, calories, fat, carbs, protein, permanent_address, current_address, birthdate, occupation) {
        return { name, calories, fat, carbs, protein, permanent_address, current_address, birthdate, occupation };
    }
    const rows = [
        createData('Name', props.data.name),
        createData('Batch', props.data.batch),
        createData('Email', props.data.email),
        createData('Contact', props.data.contact),
        createData('Admission Year', props.data.admission_year),
        createData('Passing Year', props.data.passing_year),
        createData('Permanent Address', props.data.permanent_address),
        createData('Current Address', props.data.current_address),
        createData('Date Of Birth', props.data.birthdate),
        createData('Occupation', props.data.occupation),
    ];

    const allDataOnTable = () => {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            {/* <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Calories</TableCell>
                                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell>
                            </TableRow> */}
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.calories}</TableCell>
                                    <TableCell align="right">{row.fat}</TableCell>
                                    <TableCell align="right">{row.carbs}</TableCell>
                                    <TableCell align="right">{row.protein}</TableCell>
                                    <TableCell align="right">{row.permanent_address}</TableCell>
                                    <TableCell align="right">{row.current_address}</TableCell>
                                    <TableCell align="right">{row.birthdate}</TableCell>
                                    <TableCell align="right">{row.occupation}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} size="small">
                Verify
            </Button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Verify Data</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.data.name}
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                    >


                        <div className='row'>
                            <div className='col-md-8'>
                                {allDataOnTable()}
                            </div>
                            <div className='col-md-4'>
                                <div className='border'>
                                    {props.data.photo ? <img src={`${process.env.REACT_APP_BACKEND}assets/photo/${props.data.photo}`} className="img-fluid alumniImage" /> :
                                        <img src="https://iimk.ac.in/images/facultyDefault.jpg" className="img-fluid alumniImage" />
                                    }
                                </div>
                            </div>
                        </div>
                        <hr />
                        <Divider />
                        <hr />
                        <div className='row justify-content-center'>
                            <div className='col-md-8'>


                                <iframe src={`${process.env.REACT_APP_BACKEND}assets/certificate/${props.data.certificate}`} width={'100%'} height={'800'}></iframe>

                            </div>
                        </div>


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => confirmAlumni(0)} variant="contained" color='error'>Reject</Button>
                    <Button onClick={() => confirmAlumni(1)} variant="outlined">Confirm Verify</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
                <ToastContainer />
            </Dialog>
        </React.Fragment>
    );
}