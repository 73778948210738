import React,{useEffect} from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';


const UpcomingEvents = () => {

    useEffect(() => {
        AOS.init();
      }, []);

      useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return(
        <section className="box">
        <div className="container" style={{'position':'inherit'}}>
            <div className="text-center" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <h1 className="text-white">Upcoming Events</h1>
            </div>
            <div className="row mt-5 justify-content-center" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <div className="col-lg-3">
                    <div className="blog">
                        <div className="image">
                            <img src="https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20traditional%20dance.jpg" className="img-fluid" />
                        </div>
                        <div className="p-4">
                            <h5>Skills To Develop Your Child Memory</h5>
                            <div className="p-3">
                                <span>12 April</span>
                                <span style={{'float':'right'}}>2.00 PM</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="blog">
                        <div className="image">
                            <img src="https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20annual%20day.jpg" className="img-fluid" />
                        </div>
                        <div className="p-4">
                            <h5>Skills To Develop Your Child Memory</h5>
                            <div className="p-3">
                                <span>13 April</span>
                                <span style={{'float':'right'}}>2.00 PM</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="blog">
                        <div className="image">
                            <img src="https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20annual%20day.jpg" className="img-fluid" />
                        </div>
                        <div className="p-4">
                            <h5>Skills To Develop Your Child Memory</h5>
                            <div className="p-3">
                                <span>13 April</span>
                                <span style={{'float':'right'}}>2.00 PM</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="blog">
                        <div className="image">
                            <img src="https://cache.careers360.mobi/media/schools/social-media/media-gallery/831/2019/7/22/Guwahati%20public%20school%20-%20school%20exhibition.jpg" className="img-fluid" />
                        </div>
                        <div className="p-4">
                            <h5>Skills To Develop Your Child Memory</h5>
                            <div className="p-3">
                                <span>14 April</span>
                                <span style={{'float':'right'}}>2.00 PM</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    )
}

export default UpcomingEvents;