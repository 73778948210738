import { useEffect } from 'react';
import React from "react";
import { useNavigate } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';


const Box = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
      }, [])


    return (
        <section className="box">
            <div className="container" style={{'position':'inherit'}} data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <div className="row justify-content-center">
                    <div className="text-center mb-3">
                        <h1 className="my-3 text-white" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">About Us</h1>
                    </div>
                    <div className='col-lg-6' data-aos="fade-up">
                        <div className='text-center text-white'>
                            <p style={{'fontWeight':'500'}}>Guwahati Public School also known as Guwahati Public School. The school was established in 1982. Guwahati Public School is a Co-Ed school affiliated to Central Board of Secondary Education (CBSE) . It is managed by Assam Adarsha Vidyalaya Sanstha.<br/><br/>
                            Guwahati Public School (GPS) located at Panjabari Guwahati Dt- Kamrup Assam Kamrup Assam is one of the popular schools in India. The School has been rated by 20 people on iCBSE. The School has been recognized by Central Board of Secondary Education since a long time. The Guwahati Public School has been viewed 1293 times by the visitors on iCBSE.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-up">
                        <div className="row mx-3">
                            <div className="col-md-4">
                                <div className="single_feature">
                                    <p className="text-center mt-4">Join Us</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single_feature">
                                    <p className="text-center mt-4" onClick={()=>navigate('/connect')}>Register</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single_feature">
                                    <p className="text-center mt-4">Placements</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single_feature" onClick={()=>navigate('/batch')}>
                                    <p className="text-center mt-4">Batch</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single_feature ">
                                    <p className="text-center mt-4">Recent Batch</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single_feature">
                                    <p className="text-center mt-4">Search</p>
                                </div>  
                            </div>  
                        </div>           
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Box;