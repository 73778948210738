import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Button } from '@mui/material';

import DialogModal from '../../../components/atoms/dialog/dialog';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import LinearProgress from '@mui/material/LinearProgress';

import AOS from 'aos';
import 'aos/dist/aos.css';


export default function ViewBatchPage() {

  const [alumniData, setAlumniData] = useState([]);
  const [update, setUpdate] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    viewAlumni();

  }, [update]);

  useEffect(() => {
    AOS.init();
  }, [])



  const viewAlumni = () => {
    // console.log(formData);
    axios.get(`${process.env.REACT_APP_MAIN_API}/getAlumni`)
      .then((res) => {
        console.log(res);
        setAlumniData(res.data.alumni);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const updateAlumni = (data) => {
    setUpdate(data)
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'email', headerName: 'Email', width: 130 },
    { field: 'contact', headerName: 'Contact', width: 130 },
    { field: 'batch', headerName: 'Batch', width: 130 },
    {
      field: 'isApproved', headerName: 'Action', width: 300,
      renderCell: (field) => (
        <>

          {field.row.isApproved === null &&
            <>
              <DialogModal data={field.row} viewAlumni={viewAlumni} updateAlumni={updateAlumni} />
            </>
          }


          {field.row.isApproved === 1 ?
            <>
              <Button variant="contained" size="small" color='success'>
                Approved
              </Button>
            </> : ''}

          {field.row.isApproved === 0 ?
            <>
              <Button variant="contained" size="small" color='error'>
                Rejected
              </Button>
            </> : ''}
        </>
      ),
    },

  ];

  const Loading = () => {
    return (
      <>
        <div className='container p-5'>
          <div className='row'>
            <div className='col-md-12'>
              <div className="p-4">
                <div>
                  <Skeleton height={35} count={10}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {loading ?
        <>
          <LinearProgress color="secondary"/>
          <Loading />
        </>
        :
        <div className='container m-3'>
          <div style={{ height: 500, width: '100%' }} data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
            <DataGrid
              rows={alumniData}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
            />
          </div>
        </div>
      }
    </>
  );
}