import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useState } from 'react';


function NavbarNav() {

  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
      setShow(!show);
  }
  const hideDropdown = e => {
      setShow(false);
  }

const [news, setNews] = useState(false);
const showNews = (e) => {
  setNews(!news);
}
const hideNews = (e) => {
  setNews(false);
}

const [impact, setImpact] = useState(false);
const showImpact = (e) => {
  setImpact(!impact);
}
const hideImpact = (e) => {
  setImpact(false);
}

const [connect, setConnect] = useState(false);
const showConnect = (e) => {
  setConnect(!connect);
}
const hideConnect = (e) => {
  setConnect(false);
}

  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="/" style={{'marginLeft':'100px'}}>
          <img src='assets/logo1.png' width="50" alt="logo"/>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ 'margin':'0 auto' }}
          >
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/about">ABOUT US</Nav.Link>
            <Nav.Link href="/batch">BATCH</Nav.Link>
            <NavDropdown title="ALUMNI COMMUNITY" id="collasible-nav-dropdown" show={show} onMouseEnter={showDropdown}    onMouseLeave={hideDropdown}>
              <NavDropdown.Item href="/community">CORE COMMUNITY</NavDropdown.Item>
              <NavDropdown.Item href="/agm">AGM</NavDropdown.Item>
              <NavDropdown.Item href="/finance">FINANCE</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="NEWS & EVENT" id="collasible-nav-dropdown" show={news} onMouseEnter={showNews}   onMouseLeave={hideNews}>
              <NavDropdown.Item href="/media">MEDIA</NavDropdown.Item>
              <NavDropdown.Item href="/gallery">GALLERY</NavDropdown.Item>
              <NavDropdown.Item href="/events">UPCOMING EVENTS</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="YOUR IMPACT" id="collasible-nav-dropdown" show={impact} onMouseEnter={showImpact}   onMouseLeave={hideImpact}>
              <NavDropdown.Item href="/donor">DONOR HONOR ROLL</NavDropdown.Item>
              <NavDropdown.Item href="/giving">STORIES OF GIVING</NavDropdown.Item>
              <NavDropdown.Item href="/recognition">DONOR RECOGNITION</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="CONNECT" id="collasible-nav-dropdown" show={connect} onMouseEnter={showConnect}   onMouseLeave={hideConnect}>
              <NavDropdown.Item href="/connect">REGISTER</NavDropdown.Item>
              <NavDropdown.Item href="/share">SHARE & COLLAB</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarNav;