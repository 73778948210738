import React from "react";

import Connect from "../../../components/connect/connect";




const AddBatchPage = () => {
    return (
        <section className="p-3">
            <Connect />
        </section>
    )
}

export default AddBatchPage;