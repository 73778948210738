import React,{useEffect} from "react";

import BlogSection from "../components/home/blogSection";

const MediaPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    return(
       <>
            <BlogSection />
       </>
    )
}

export default MediaPage;